<template>
  <v-container>
    <v-row
      justify="center"
      :class="isXs?'titleSearch':''"
    >
      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <base-material-card
          color="#fb8c00"
          light
          max-width="100%"
          width="600"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="text-h4 text-sm-h3 text-md-h3 text-lg-h3 text-xl-h3 font-weight-bold mb-2">
                {{ systemName }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-text-field
              v-model="username"
              color="secondary"
              label="账号"
              prepend-icon="mdi-email"
            />

            <v-text-field
              v-model="password"
              class="mb-8"
              color="secondary"
              label="密码"
              type="password"
              prepend-icon="mdi-lock-outline"
              @keyup.enter="login"
            />

            <v-btn
              large
              color=""
              depressed
              class="v-btn--text success--text"
              @click="login"
            >
              现在登录
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      color="red"
      top
    >
      {{ error_text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay
      opacity="0.98"
      :value="overlay"
    >
      <v-alert
        dense
        type="info"
      >
        系统正在进行初始化，长时间未响应，请刷新重试...
      </v-alert>
    </v-overlay>
  </v-container>
</template>

<script>
  import jsonTree from '../../utils/tree'
  import http from '../../api'
  import AES from '@/utils/aes'
  export default {
    data: () => ({
      systemName: '招商引资重点项目库',
      snackbar: false,
      error_text: '',
      username: '',
      password: '',
      overlay: false,
      isXs: false,
    }),
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      localStorage.clear()
      await this.getSystemInitInfo()
      if (localStorage.systemName && localStorage.xApp) {
        this.systemName = localStorage.systemName
      } else {
        await this.getSystemInitInfo()
        this.overlay = true
      }
    },
    methods: {
      async getSystemInitInfo () {
        // 获取APPkey，测试的话需要传测试系统的域名，不传就会自动获取真实的发起请求的域名
        await http.system.getWhoami('http://zdxm.sdcom.gov.cn')
          .then(async res => {
            localStorage.xApp = res.data.data
            // 获取系统初始化信息
            await http.system.getConfig(res.data.data)
              .then(res => {
                localStorage.setItem('systemId', res.data.data.id)
                localStorage.setItem('systemLogo', res.data.data.logo)
                localStorage.setItem('systemName', res.data.data.name)
                localStorage.setItem('systemConfig', JSON.stringify(res.data.data.config))
                document.title = res.data.data.name
                if (localStorage.systemName && localStorage.xApp) {
                  this.systemName = localStorage.systemName
                } else {
                  this.getSystemInitInfo()
                  this.overlay = true
                }
              })
              .catch(err => {
                console.log(err)
                this.overlay = true
              })
          })
          .catch(err => {
            console.log(err)
            this.overlay = true
          })
      },
      login () {
        http.login.password(this.username, AES.encrypt(this.password))
          .then(async (res) => {
            if (res.data.code === 200) {
              localStorage.setItem('token', 'Bearer ' + res.data.data)
              await this.getUserInfo()
              await this.initSystem()
            // this.getOrgs();
            } else {
              this.snackbar = true
              this.error_text = res.data.data
            }
          })
          .catch((err) => {
            const errData = err.data
            if (errData.code === 422) {
              this.snackbar = true
              this.error_text = '账号或密码不能为空！'
            } else if (errData.code === 403) {
              this.snackbar = true
              this.error_text = '账号或密码错误！'
            } else {
              this.snackbar = true
              this.error_text = errData.msg
            }
          })
      },
      async getUserInfo () {
        const res = await this.$axios.get('/me')
        const meInfo = res.data.data
        //! 老旧的代码, 未梳理完成，暂不删除
        const isAdmin = meInfo.roles.includes('ROLE_ADMIN') || meInfo.roles.includes('ROLE_SITE_ADMIN')
        // 会商人员
        const isHshow = meInfo.roles.includes('ROLE_TZCJCFZRSHYJ') || meInfo.roles.includes('ROLE_KFQCNBYJ') || meInfo.roles.includes('ROLE_TZCJCNBYJ')
        localStorage.isHshow = isHshow
        // 会商审核人员
        const ishsEdit = meInfo.roles.includes('ROLE_TZCJCFZRSHYJ') || meInfo.roles.includes('ROLE_TZCJCNBYJ')
        localStorage.ishsEdit = ishsEdit
        // 市级审核
        const isMarket = meInfo.roles.includes('ROLE_SHIJI')
        // 审核人员（对外推介，重点外商可以在项目任何状态下编辑，删除）
        const isEdit = meInfo.roles.includes('ROLE_TZCJCFZRSHYJ') || meInfo.roles.includes('ROLE_TZCJCNBYJ') || meInfo.roles.includes('ROLE_TZCJZX') || meInfo.roles.includes('ROLE_SHIJI')
        localStorage.isEdit = isEdit
        // 代表处
        const isDeputy = meInfo.roles.includes('ROLE_DAIBIAOCHU')
        localStorage.isDeputy = isDeputy

        localStorage.isAdmin = isAdmin
        localStorage.isMarket = isMarket
        localStorage.userName = meInfo.nickname
        localStorage.userId = meInfo.id
        localStorage.roles = meInfo.roles.toString()
        await this.getUserGroupTree(meInfo.orgId)
        localStorage.orgId = meInfo.orgId
      },
      async getUserGroupTree (orgId) {
        const res = await this.$axios.get('/dictionaries/roles')
        const resData = res.data.data
        for (let inx = 0; inx < resData.length; inx++) {
          // 推送园区为父级
          if (resData[inx].value === 'ORG_TUISONGYUANQU') {
            const userGroupTree = this.treeData(resData, resData[inx].id)
            this.userGroupTree = userGroupTree
            this.setName(userGroupTree, orgId)
          }
        }
        // resData.map(val => {
        //   // 推送园区为父级
        //   if (val.value === 'ORG_TUISONGYUANQU') {
        //     const userGroupTree = this.treeData(resData, val.id)
        //     this.userGroupTree = userGroupTree
        //     this.setName(userGroupTree, orgId)
        //   }
        // })
      },
      treeData (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        const list = []
        return cloneData.filter(father => {
          const branchArr = cloneData.filter(child => father.id === child.parentId)
          branchArr.length > 0 ? father.children = branchArr : father.children = []
          return father.parentId + '' === id + ''
        })
      },

      setName (datas, orgId) { // 遍历树  获取id数组
        datas.map(val => {
          // console.log(val);
          if (val.id === orgId) {
            // 组织机构区县的
            localStorage.orgQX = true
          }
          if (val.children) { // 存在子节点就递归
            this.setName(val.children, orgId);
          }
        })
      },
      async getSystemForms () {
        const forms = []
        // 获取所有表单类别
        const systemFormTypesData = await this.$axios.get('/categories/form')
        // 获取所有表单
        const systemFormsData = await this.$axios.get('/forms')
        // 项目推进情况
        const advanceForms = systemFormsData.data.data.filter((val) => {
          return val.type === 'evolve'
        })
        if (advanceForms) {
          localStorage.setItem('advanceForms', JSON.stringify(advanceForms[0]))
        }
        // 获取系统ID
        let systemId = localStorage.systemId
        // 获取板块权限
        if (JSON.parse(localStorage.getItem('systemConfig')).advance) {
          // 获取系统初始化配置信息
          const system = await this.$axios.get('http://api.invest.sdcom.gov.cn/api/sites/config')
          // 获取系统ID
          systemId = system.data.data.id
        }
        // const systemForms = systemFormsData.data.data
        // 遍历所有的系统类别，ID相等则返回
        const systemFormTypes = systemFormTypesData.data.data.filter((val) => {
          return val.siteId === parseInt(systemId)
        })
        // 1
        console.log('systemFormTypes', systemFormTypes)

        systemFormTypes.map(formtype => {
          console.log('formtype', formtype)

          // 构建一级表单类型对象
          const formTypes = {
            id: formtype.id,
            name: formtype.name,
            children: [],
          }
          // 遍历所有表单，表单id与类别相等则返回
          const systemForms = systemFormsData.data.data.filter((forms) => {
            return forms.type === 'normal' && forms.categoryId === formtype.id && !forms.offline
          })
          // 对表单进行排序
          systemForms.sort((a, b) => {
            return a.weight - b.weight
          })
          // 赋值表单二级数组
          formTypes.children = systemForms
          // 添加至构建实体的数组
          forms.push(formTypes)
        })
        return forms
      },
      async initSystem () {
        // 将获取到的所有表单存储到本地
        localStorage.setItem('newForms', JSON.stringify(await this.getSystemForms()))
        this.$router.push('/')
      },
      // 获取组织机构
      getOrgs () {
        this.$axios.get('/orgs', {
          params: {
            site: localStorage.systemId,
          },
        })
          .then(res => {
            const orgsData = res.data.data
            const orgs = orgsData.sort((a, b) => {
              return a.weight - b.weight
            })
            const orgTree = jsonTree(orgs, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
            console.log(orgTree)
            this.$store.commit('SET_ORGS', orgTree)
            console.log(this.$store.state.orgs)
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .titleSearch .v-text-field__details{
display:none
}
</style>
